<template>
  <div>
      <div id="app">
        <router-view/>
      </div>
  </div>
</template>

<script>
require('@/assets/css/style.less');

export default {
  name: 'app',
}
</script>

<style>


</style>
