<template>
  <div>
            <div class="row" :class="$style.customRow">
          <div class="col-md-2 col-sm-2 col-xs-2">
            <label :class="$style.customLabel">Title:*</label>
          </div>
          <div class="col-md-9 col-sm-9 col-xs-9">
            <input v-model="assignmentTitle" type="text" placeholder="Assignment Title (Required)" class="form-control" ref="txtTitle">
          </div>
        </div>
        <div class="row" :class="$style.customRow">
          <div class="col-md-2 col-sm-2 col-xs-2">
            <label :class="$style.customLabel">Description:</label>
          </div>
          <div class="col-md-9 col-sm-9 col-xs-9">
            <textarea v-model="assignmentDesc" class="form-control" rows="6" :class="$style.customTextarea" placeholder="Assignment Description" ref="txtDesc"></textarea>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'firstStep',
  data() {
    return {
      assignmentTitle: '',
      assignmentDesc: '',
    };
  },
  beforeDestroy() {
    const data = {};
    data.assignment_name = this.$refs.txtTitle.value;
    data.assignment_desc = this.$refs.txtDesc.value;
    this.$parent.setAssignmentData(data);
  },
  props: ['title', 'desc'],
  beforeMount() {
    this.assignmentTitle = this.title;
    this.assignmentDesc = this.desc;
  },
};
</script>

<style src='./style.less' lang='less' module/>

