<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container">
      <div class="row mt-5">
        <div class="col-3 mt-5">
          <!-- <button class="btn" style="background-color: #6f2b8f; color: white;">BACK</button> -->
        </div>
      </div>
      <template v-if="parent">
        <div class="row">
          <button type="button" class="mt-5 ml-4 btn" style="background-color: #ededee; color: #ededee;">s</button>
        </div>
        <div class="row">
          <div class="col-md-4 mt-3" v-for="(item, i) in resources.filter(x => x.package_parent_id === this.$route.params.id)" :key="i">
            <div class="row" :class="$style.customCard" @click="setChild(item.package_id)" :title="item.package_name">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="item.package_image ? item.package_image : '../../../static/img/no-cover.png'"  :class="$style.coverImg" style="width: 100px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">{{item.package_name}}</h1>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="!parent">
        <div class="row">
          <button type="button" @click="parent = true;" class="mt-5 ml-4 btn" style="background-color: white; color: #6f2b8f; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;"><i class="fa-2x fas fa-arrow-alt-circle-left"></i></button>
        </div>
        <div class="row">
          <div class="col-md-4 mt-3" v-for="(child, i) in childData" :key="i">
            <div class="row" :class="$style.customCard" :title="child.link_name" @click="openLink(child)">
              <div class="col-md-6 justify-content-center align-self-center">
                <img :src="child.thumbnail_url ? child.thumbnail_url : '../../../static/img/no-cover.png'"  :class="$style.coverImg" style="width: 100px">
              </div>
              <div class="col-md-6 justify-content-center align-self-center">
                <h1 :class="$style.contentTitle">{{child.link_name}}</h1>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import HeaderCustom from '@/components/HeaderCustom';
import service from '../../services/content';

export default {
  name: 'teacherResources',
  data() {
    return {
      resources: [],
      childData: [],
      parent: true,
    };
  },
  components: {
    HeaderCustom,
  },
  computed: {
  },
  beforeMount() {
    this.getPackages();
  },
  watch: {
  },
  methods: {
    async getPackages() {
      if (!this.$route.params.id) this.$router.go(-1);
      const data = await service.getLink();
      this.resources = data.data.data.filter(x => x.package_type === 'link');
    },
    openLink(item) {
      window.open(item.link_url, '_blank');
    },
    setChild(id) {
      const subs = this.resources.find(x => x.package_id === id);
      this.childData = subs.package_links;
      this.parent = false;
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

