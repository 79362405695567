<template>
  <div>
    <HeaderCustom></HeaderCustom>

    <div class="container">

      <div :class="$style.divCreateAssignment">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12" :class="$style.divTitle">
            <h3>Create Assignment</h3>
          </div>
        </div>
        <div class="row" :class="$style.divSteps">
          <div class="col-md-3 col-sm-3 col-xs-3 step" :class='$style.customStep' id='step1' @click="currentstep=1">DETAILS</div>
          <div class="col-md-3 col-sm-3 col-xs-3 step" :class='$style.customStep' id='step2' @click="currentstep=2"> RESOURCES</div>
          <div class="col-md-3 col-sm-3 col-xs-3 step" :class='$style.customStep' id='step3' @click="currentstep=3"> ASSIGNEES</div>
          <div class="col-md-3 col-sm-3 col-xs-3 step" :class='$style.customStep' id='step4' @click="currentstep=4"> OPTIONS</div>
        </div>
        <FirstStep
          v-if="this.currentstep === 1"
          :title='this.assignmentData.assignment_name' 
          :desc='this.assignmentData.assignment_desc'
          ></FirstStep>
          
        <SecondStep 
        v-if="this.currentstep === 2" 
        :resources='this.assignmentData.assignment_scorms? this.globalSelectedLessons : []'
        ></SecondStep>
        
        <ThirdStep 
        v-if="this.currentstep === 3"
        ></ThirdStep>
          
        <FourthStep v-if="this.currentstep === 4" 
          :sDate='this.assignmentData.assignment_start_date ? this.assignmentData.assignment_start_date : null'
          :dDate='this.assignmentData.assignment_end_date ? this.assignmentData.assignment_end_date : null' 
          :block='this.assignmentData.block_after_due_date ? this.assignmentData.block_after_due_date : false'
          :single='this.assignmentData.single_access ? this.assignmentData.single_access : false'
          ></FourthStep>

        <div class="row" :class="$style.divBtn">
          <div class="col-md-2">
            <button v-if="this.currentstep !== 1" class="btn" :class="$style.backBtn" @click="decreaseStep">
              <i class="fa fa-arrow-left fa-3x" aria-hidden="true" :class="$style.icon"></i>
            </button>

          </div>
          <div class="col-md-2 offset-md-8">
            <button v-if="this.currentstep !== 4" class="btn" :class="$style.nextBtn" @click="increaseStep">
              <i class="fa fa-arrow-right fa-3x" aria-hidden="true" :class="$style.icon"></i>
            </button>
            <button v-if="this.currentstep === 4" class="btn" :class="$style.btnCreate" @click="createAssignment">
              Create Assignment
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import swal from 'sweetalert';
import HeaderCustom from '@/components/HeaderCustom';
import FirstStep from '@/components/Assignments/Create/FirstStep';
import SecondStep from '@/components/Assignments/Create/SecondStep';
import ThirdStep from '@/components/Assignments/Create/ThirdStep';
import FourthStep from '@/components/Assignments/Create/FourthStep';

import contentService from '@/services/content';
import config from '@/config';

export default {
  name: 'assignmentCreate',
  data() {
    return {
      currentstep: 1,
      assignmentData: {},
      globalSelectedLessons: [],
    };
  },
  components: {
    HeaderCustom,
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
  },
  methods: {
    increaseStep() {
      this.currentstep += 1;
    },
    decreaseStep() {
      this.currentstep -= 1;
    },
    async createAssignment() {
      // const datastring = JSON.stringify(this.assignmentData);
      if (
        !this.assignmentData.assignment_start_date ||
        !this.assignmentData.assignment_end_date ||
        !this.assignmentData.assignment_name ||
        this.assignmentData.assignment_scorms.length === 0 ||
        !this.assignmentData.assignment_type
      ) {
        swal('Error!', 'The required fields must be filled.', 'error');
      } else {
        this.assignmentData.assignment_start_date = `${this.assignmentData.assignment_start_date.split('T')[0]} ${this.assignmentData.assignment_start_date.split('T')[1].split('.')[0]}`;
        this.assignmentData.assignment_end_date = `${this.assignmentData.assignment_end_date.split('T')[0]} ${this.assignmentData.assignment_end_date.split('T')[1].split('.')[0]}`;
        let res = null;
        if (this.assignmentData.assignment_type === 'mixed') {
          const { studentIds } = this.assignmentData;
          const { groupIds } = this.assignmentData;
          delete this.assignmentData.groupIds;
          delete this.assignmentData.studentIds;
          this.assignmentData.ids = studentIds;
          this.assignmentData.assignment_type = 'individual';
          res = await contentService.setAssignment(this.assignmentData);
          this.assignmentData.ids = groupIds;
          this.assignmentData.assignment_type = 'group';
          res = await contentService.setAssignment(this.assignmentData);
        } else {
          res = await contentService.setAssignment(this.assignmentData);
        }
        if (res.success === true) {
          swal('Assignment', 'Created Successfully!', 'success').then(() => {
            this.$router.push({
              name: 'teacherAssignments',
            });
          });
        } else {
          swal('', 'An error occured while you creating assignments', 'error');
          console.log('ASSIGNMENT CREATION ERROR', res.data);
        }
      }
    },
    setAssignmentData(data) {
      /*eslint-disable*/
      for (const item of Object.keys(data)) {
        if (item === 'assignment_scorms') {
          this.globalSelectedLessons = data[item];
          this.assignmentData[item] = data[item].map(x => x.id);
        } else {
          this.assignmentData[item] = data[item];
        }
      }
    },
  },
  mounted() {
    const stepId = `step${this.currentstep}`;
    const step = document.getElementById(stepId);
    step.style.backgroundColor = config.colors.primaryColor;
    step.style.color = 'white';
  },
  updated() {
    const stepsArr = document.getElementsByClassName('step');
    /*eslint-disable*/
    for (const item of stepsArr) {
      item.style.backgroundColor = 'white';
      item.style.color = config.colors.primaryColor;
    }

    const stepId = `step${this.currentstep}`;
    const step = document.getElementById(stepId);
    step.style.backgroundColor = config.colors.primaryColor;
    step.style.color = 'white';
  },
};
</script>

<style src='./style.less' lang='less' module/>

