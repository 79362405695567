<template>
  <div :class="$style.divLogin">
    <img src="static/img/resim.png" style="width: 100%; height: 100%; position: absolute; bottom: 0;" alt="">
    <!-- <div :class="$style.divHelpDesk">
      <HelpDesk bgcolor='white' :txtcolor='primaryColor'></HelpDesk>
    </div> -->
    <div :class="$style.loginform">
      <div style="color:white; text-align:center;margin-bottom:20px">
          <div :class="$style.logo"> </div>
        <!-- <a :href="`https://www.myeduclass.com`">
        </a> -->
        <!-- <h1>Login</h1> -->
      </div>
      <div class="row" :class="$style.inputs">
        <div class="col-md-12">
          <i style="position: absolute;margin: 10px; color: #b5badd;" class="fas fa-user"></i>
          <input v-model="username" style="padding-left:30px;border:none;background-color: rgba(233, 236, 239, 0.32941176470588235) !important;" type="text" class="form-control mb-3" placeholder="User Name" :class="$style.inputText">
          <i style="position: absolute;margin: 10px; color: #b5badd;" class="fas fa-unlock-alt"></i>
          <input v-model="password" style="padding-left:30px;border:none;background-color: rgba(233, 236, 239, 0.32941176470588235) !important;" type="password" class="form-control mb-3" placeholder="Password" :class="$style.inputText" @keyup.enter="login">
          <label style="color:black;user-select: none;"><input type="checkbox" value="" class="mb-3"> Remember Me</label>
          <button class="btn btn-block" :class="$style.loginButton" @click="login">Login</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import swal from 'sweetalert';
  import config from '@/config';
  import authService from '@/services/auth';
  import axios from 'axios';

  export default {
    name: 'login',
    data() {
      return {
        username: '',
        password: '',
        isChecked: false,
        primaryColor: config.primaryColor,
        ipAdress: '',
      };
    },
    beforeMount() {
      localStorage.clear();
    },
    components: {
    },
    methods: {
      async login() {
        const res = await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });
        if (res.success) {
          const ipData = await axios.get("https://api.ipify.org?format=json")
          .catch(err => {
              console.log('err', err);
          });

          this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

          const userAgent = navigator.userAgent;
          await authService.loginLog(userAgent, this.ipAdress).then(() => {
            this.$router.push('/home');
          }).catch(err => console.log('err', err));
        } else {
          swal('Error!', 'Username or Password is invalid.', 'error');
        }
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style>
  .abcRioButton {
    width: 300px !important;
  }

</style>
