<template>
    <div>
        <HeaderCustom></HeaderCustom>
        <div :class="$style.divReport" class="container">
          <div class="row justify-content-center mt-5">
            <div :class="$style.title">Get Assignments Report</div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-2">Assignment Type:</div>
            <div class="col-md-5">
              <v-select v-model="selectedType" label='name' @input="newAssignees()" :options="this.assignmentTypes"></v-select>
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-2">Assignee:</div>
            <div class="col-md-5">
              <v-select v-model="selectedAssignee" label='name' :options="this.selectedType === 'Select Type' ||  this.selectedType === null ? [] : this.assigneesArr"></v-select>
            </div>
          </div>
          <div class="row align-items-center mt-2">
          <div class="col-md-2">Date:</div>
            <div class="col-md-2">
                Start Date:
              <input class="form-control" v-model="selectedStartDate" name="start_date" type="date" id="example-date-input">
            </div>
            <div class="col-md-1">
            </div>
            <div class="col-md-2">
                End Date:
              <input class="form-control" v-model="selectedEndDate" :disabled="selectedStartDate === null" name="start_date" :min="selectedStartDate" type="date" id="example-date-input">
            </div>

          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-2">Assignments:</div>
            <div class="col-md-5">
              <v-select v-model="selectedAssignments" :clearable="false" label='assignment_name_show' @input="getNewArray()" :options="this.selectedAssignee === 'Select Type' ||  this.selectedAssignee === null ? [] : this.assignmentsArr"></v-select>
            </div>
          </div>
          <div class="row mt-2">
            <button disabled class="col-md-2 offset-md-7 btn" :class='$style.btnReport' @click='getReport' id="btnReport">Get Report</button>
            <button disabled class="col-md-2 btn ml-2" :class='$style.btnReport' @click='print' id="btnPrint">Print</button>
          </div>
        <div class="row" v-if="tableData.length  && ready> 0 && type === 'group'" id="section-to-print">
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-bordered" :class="$style.customTable">
              <thead v-for="(item) in this.selectedAssignments" :key="item.id">
                <tr>
                  <td style="color:red">#</td>
                  <!-- <td style="color:red">Assignments Name</td> -->
                  <td style="color:red">{{item.assignment_name}}</td>
                  <!-- <td style="color:red">Assignee</td>
                  <td style="color:red">{{this.selectedAssignee}}</td> -->
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Student Name</th>
                  <th>Total Checks</th>
                  <th>Total Errors</th>
                  <th>Total Mistakes</th>
                  <th>Average Score</th>
                  <th>Total Time</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody v-for="(item,i) in tableData" :key="i">
                <tr>
                  <td>{{ i+1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.total_checks_count }}</td>
                  <td>{{ item.total_errors_count }}</td>
                  <td>{{ item.total_mistake_count }}</td>
                  <td>{{ item.avg_score.toFixed(0) }}%</td>
                  <td>{{ millisToMinutesAndSeconds(item.total_time) }}</td>
                  <td>
                    <vue-custom-tooltip label="Details" position="is-left">
                    <a data-toggle="modal" data-target="#editModal" @click="reportDetails(item.user_id)"><i class="fas fa-eye"></i></a>
                    </vue-custom-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
              
          </div>
        </div>
        <div class="row" v-else-if="tableData.length  && ready> 0 && type === 'individual'" id="section-to-print">
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-bordered" :class="$style.customTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Student Name</th>
                  <th>Lesson Name</th>
                  <th>Total Checks</th>
                  <th>Total Errors</th>
                  <th>Total Mistakes</th>
                  <th>Average Score</th>
                  <th>Total Time</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody v-for="(item,i) in tableData" :key="i">
                <tr>
                  <td>{{ i+1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.scorm_name }}</td>
                  <td>{{ item.total_checks_count }}</td>
                  <td>{{ item.total_errors_count }}</td>
                  <td>{{ item.total_mistake_count }}</td>
                  <td>{{ item.avg_score.toFixed(0) }}%</td>
                  <td>{{ millisToMinutesAndSeconds(item.total_time) }}</td>
                  <td>
                    <vue-custom-tooltip label="Details" position="is-left">
                    <a data-toggle="modal" data-target="#editModal" @click="reportDetails(item.user_id)"><i class="fas fa-eye"></i></a>
                    </vue-custom-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else-if="!ready">
          <InnerLoading></InnerLoading>
        </div>
      </div>
      <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" style="max-width:90%;">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Assignments Details: {{checkReport[0] ? checkReport[0].report.name : ''}} </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row" :class="$style.customRow" v-for="(check,x) in checkReport" :key="x">
                      <div class="jumbotron col-sm-3 col-md-3" :class="$style.customJumbotron">
                          
                            <h6 :class="$style.txtLessonName">{{check.scorm_name}}</h6>
                            <hr>
                            <img :class="$style.lessonImg" :src="check.scorm_image">
                            <hr>
                            <h6 class="text-center" :class="$style.txtLessonName">Description</h6>
                            <hr>
                            <i>{{check.scorm_desc ? check.scorm_desc : 'Lesson has no description.'}}</i>
                          
                      </div>
                      <div class="jumbotron col-sm-9 col-md-9" :class="$style.customJumbotronViceVersa">
                            <div class="table-responsive">
                              <table class="table table-hover table-bordered">
                                <thead>
                                  <tr>
                                    <th>Lesson</th>
                                    <th>Score</th>
                                    <th>Checks</th>
                                    <th>Errors</th>
                                    <th>Mistakes</th>
                                    <th>Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      Total
                                    </td>
                                    <td>{{Math.floor(check.report.scorm_scores.total_score)}}</td>
                                    <td>{{check.report.scorm_scores.total_checks_count}}</td>
                                    <td>{{check.report.scorm_scores.total_errors_count}}</td>
                                    <td>{{check.report.scorm_scores.total_mistake_count}}</td>
                                    <td>{{millisToMinutesAndSeconds(check.report.scorm_scores.total_time)}}</td>
                                  </tr>
                                  <tr>
                                    <td colspan="6">Activities</td>
                                  </tr>
                                </tbody>
                                <tbody v-if="check.report.scorm_scores.paginated_results.length === 0">
                                  <tr>
                                    <td colspan="6">
                                      There is no activity.
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else v-for="(item,i) in check.report.scorm_scores.paginated_results" :key="i">

                                  <tr>
                                    <td>{{item.page_name}}</td>
                                    <td>{{Math.floor(item.score * 100)}}%</td>
                                    <td>{{item.checks_count}}</td>
                                    <td>{{item.errors_count}}</td>
                                    <td>{{item.mistake_count}}</td>
                                    <td>{{millisToMinutesAndSeconds(item.time)}}
                                    </td>

                                  </tr>

                                </tbody>
                              </table>
                            </div>
                      </div>
                        
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import HeaderCustom from '@/components/HeaderCustom';
import InnerLoading from '@/components/InnerLoading';
import contentService from '@/services/content';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import moment from 'moment';

export default {
  name: 'assignmentReport',
  data() {
    return {
      assignmentTypes: [
        'group',
        'individual',
      ],
      selectedStartDate: null,
      selectedEndDate: null,
      selectedType: 'Select Type',
      assignmentType: 'group',
      selectedAssignee: 'Select Type',
      assigneesArr: [],
      assignmentsArr: [],
      selectedAssignments: [],
      tableData: [],
      studentDetails: [],
      modalData: null,
      modalLoading: false,
      ready: true,
      type: null,
      reportAll: [],
      checkReport: [],
    };
  },
  computed: {
    ...mapState(['assignmentss']),
    ...mapState(['assignments']),
    ...mapState(['individualAssignments']),
  },
  components: {
    HeaderCustom,
    InnerLoading,
    VueCustomTooltip,
  },
  async beforeMount() {
    // this.assigneesArr = _.uniqBy(this.assignments
    //   .filter(x => x.assignment_type === 'group'), 'name').map(x => x.name);
    await this.getLmsAssignments();
    await this.getLmsIndAssignments();
  },
  watch: {
    // selectedType(e) {

    //   // this.selectedAssignee = 'Select an Assignee';
    //   // this.assignmentType = e.toLowerCase();
    //   // this.assigneesArr = [];
    //   console.log('test');
    // },
    selectedStartDate(e) {
      // eslint-disable-next-line
      // const moment = require('moment');
      // console.log('start', moment(this.assignments[0].start_date).format('YYYY-MM-DD'));
      // console.log('pure', this.assignments[0].start_date);
      // this.selectedEndDate = null;
      this.assignmentsArr = [];
      this.tableData = [];
      this.selectedAssignments = [];
      document.getElementById('btnReport').disabled = true;
      document.getElementById('btnPrint').disabled = true;
      if (this.selectedType === 'group') {
        this.assignmentsArr = this.assignments
            .filter(x => moment(x.start_date).format('YYYY-MM-DD') >= e && x.name === this.selectedAssignee).map(x => ({
              assignment_name_show: `${x.assignment_name} ( ${x.start_date.split(' ')[0]} )`,
              assignment_name: x.assignment_name,
              lesson_name: x.scorm.scorm_name,
              id: x.assignment_id,
              start_date: x.start_date,
              end_date: x.end_date,
            }));
        if (this.selectedEndDate != null) {
          this.assignmentsArr = this.assignmentsArr.filter(x => moment(x.start_date).format('YYYY-MM-DD') <= this.selectedEndDate);
        }

        this.assignmentsArr = _.uniqBy(this.assignmentsArr, 'assignment_name');
      } else if (this.selectedType === 'individual') {
        this.assignmentsArr = this.individualAssignments
            .filter(x => moment(x.start_date).format('YYYY-MM-DD') >= e && x.name === this.selectedAssignee).map(x => ({
              assignment_name_show: `${x.assignment_name} ( ${x.start_date.split(' ')[0]} )`,
              assignment_name: x.assignment_name,
              lesson_name: x.scorm.scorm_name,
              id: x.assignment_id,
              start_date: x.start_date,
              end_date: x.end_date,
            }));
        if (this.selectedEndDate != null) {
          this.assignmentsArr = this.assignmentsArr.filter(x => moment(x.start_date).format('YYYY-MM-DD') <= this.selectedEndDate);
        }
        this.assignmentsArr = _.uniqBy(this.assignmentsArr, 'assignment_name');
      }
    },
    selectedEndDate(e) {
        // console.log('start', this.selectedStartDate);
        // eslint-disable-next-line
      // const moment = require('moment');
      // console.log('end', e);
      // console.log('assignment', this.assignments);
      this.assignmentsArr = [];
      this.tableData = [];
      this.selectedAssignments = [];
      document.getElementById('btnReport').disabled = true;
      document.getElementById('btnPrint').disabled = true;
      if (this.selectedType === 'group') {
        this.assignmentsArr = this.assignments
            .filter(x => moment(x.start_date).format('YYYY-MM-DD') <= e &&
             moment(x.start_date).format('YYYY-MM-DD') >= this.selectedStartDate &&
             x.name === this.selectedAssignee)
            .map(x => ({
              assignment_name_show: `${x.assignment_name} ( ${x.start_date.split(' ')[0]} )`,
              assignment_name: x.assignment_name,
              lesson_name: x.scorm.scorm_name,
              id: x.assignment_id,
              start_date: x.start_date,
              end_date: x.end_date,
            }));
        this.assignmentsArr = _.uniqBy(this.assignmentsArr, 'assignment_name');
      } else if (this.selectedType === 'individual') {
        this.assignmentsArr = this.individualAssignments
            .filter(x => moment(x.start_date).format('YYYY-MM-DD') <= e &&
             moment(x.start_date).format('YYYY-MM-DD') >= this.selectedStartDate &&
             x.name === this.selectedAssignee)
            .map(x => ({
              assignment_name_show: `${x.assignment_name} ( ${x.start_date.split(' ')[0]} )`,
              assignment_name: x.assignment_name,
              lesson_name: x.scorm.scorm_name,
              id: x.assignment_id,
              start_date: x.start_date,
              end_date: x.end_date,
            }));
        this.assignmentsArr = _.uniqBy(this.assignmentsArr, 'assignment_name');
      }
    },
    selectedAssignee(e) {
      this.tableData = [];
      this.selectedAssignments = [];
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      document.getElementById('btnReport').disabled = true;
      document.getElementById('btnPrint').disabled = true;
      if (e !== 'Select an Assignee' && this.selectedType === 'group') {
        this.assignmentsArr = this.assignments
          .filter(x => x.name === e).map(x => ({
            assignment_name_show: `${x.assignment_name} ( ${x.start_date.split(' ')[0]} )`,
            assignment_name: x.assignment_name,
            lesson_name: x.scorm.scorm_name,
            id: x.assignment_id,
            start_date: x.start_date,
            end_date: x.end_date,
          }));
        this.assignmentsArr = _.uniqBy(this.assignmentsArr, 'assignment_name');
      } else if (e !== 'Select an Assignee' && this.selectedType === 'individual') {
        this.assignmentsArr = this.individualAssignments
          .filter(x => x.name === e).map(x => ({
            assignment_name_show: `${x.assignment_name} ( ${x.start_date.split(' ')[0]} )`,
            assignment_name: x.assignment_name,
            lesson_name: x.scorm.scorm_name,
            id: x.assignment_id,
            start_date: x.start_date,
            end_date: x.end_date,
          }));
        this.assignmentsArr = _.uniqBy(this.assignmentsArr, 'assignment_name');
      }
    },
    // selectedAssignments(e) {
    //   this.tableData = [];
    //   console.log('e', e);
    //   if (e.assignment_name) {
    //     document.getElementById('btnReport').disabled = false;
    //   } else {
    //     document.getElementById('btnReport').disabled = true;
    //     this.assigneesArr = [];
    //   }
    // },
  },
  methods: {
    ...mapActions(['getLmsAssignments', 'getLmsIndAssignments']),
    getNewArray() {
      this.tableData = [];
      if (this.selectedAssignments.assignment_name) {
        const newArray = new Array(this.selectedAssignments);
        this.selectedAssignments = newArray;
        if (this.selectedAssignments[0].assignment_name) {
          document.getElementById('btnReport').disabled = false;
        } else {
          document.getElementById('btnReport').disabled = true;
        }
      }
    },
    dateFilter(startDate) {
      return startDate.split(' 00:00:00');
    },
    async newAssignees() {
      this.selectedAssignee = [];
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      document.getElementById('btnReport').disabled = true;
      document.getElementById('btnPrint').disabled = true;
      if (this.selectedType === 'group') {
        this.assigneesArr = _.uniqBy(this.assignments
        .filter(x => x.assignment_type === 'group'), 'name').map(x => x.name);
        this.assigneesArr = this.assigneesArr.sort();
      } else {
        // eslint-disable-next-line
        if (this.individualAssignments) {
          this.assigneesArr = _.uniqBy(this.individualAssignments
          .filter(x => x.assignment_type === 'individual'), 'name').map(x => x.name);
        } else {
          const assignment = await contentService.getAssignments(1, 'id', 'individual');
          this.$store.commit('setIndAssignments', assignment);
          this.assigneesArr = _.uniqBy(assignment
          .filter(x => x.assignment_type === 'individual'), 'name').map(x => x.name);
        }
      }
    },
    async getReport() {
      this.ready = false;
      this.tableData = [];
      let allLessons = [];
      let searchAssignments = null;
      if (this.selectedType === 'group') {
        searchAssignments = this.assignments;
      } else {
        searchAssignments = this.individualAssignments;
      }
      // eslint-disable-next-line
      for (const lesson of this.selectedAssignments) {
        // SEÇİLİ LESSONIN BİLGİLERİNİ KULLANARAK ONUNLA
        // AYNI ASSIGNMENTDAKİ TÜM DERSLERİ YENİ BİR ARRAYE EKLE
        const allScorms = searchAssignments.filter(x => x.assignment_name === lesson.assignment_name
          && x.start_date === lesson.start_date
            && x.end_date === lesson.end_date
            && x.name === this.selectedAssignee).map(x => ({
              assignment_name: x.assignment_name,
              lesson_name: x.scorm.scorm_name,
              id: x.assignment_id,
              // start_date: x.start_date,
              // end_date: x.end_date,
            }));
        allLessons = [...allLessons, ...allScorms];
      }
      const assignmentDatas = [];
      for (const item of allLessons) {  //eslint-disable-line
        const assignmentData = searchAssignments.find(x => x.assignment_id === item.id);
        const assignmentDetailsData = await contentService.getPaginatedScores(assignmentData.assignment_id); //eslint-disable-line
        if (this.selectedType === 'group') {
          if (assignmentDetailsData.success) {
            assignmentDatas.push(assignmentDetailsData.data);
          }
        } else {
          assignmentDatas.push(assignmentDetailsData.data);
        }
      }
      let allAssignments = [];
      // let allAssignments2 = [];
      if (this.selectedType === 'group') {
        for (const item of assignmentDatas) { //eslint-disable-line
          allAssignments = [...allAssignments, ...item.users];
          // allAssignments2 = [...allAssignments];
        }
        // console.log('deneme', allAssignments2);
        for (const item of allAssignments) { //eslint-disable-line
          const userScores = allAssignments.filter(x => x.user_id === item.user_id);
          // console.log(item.user_ id, userScores);
          let totalCheck = 0;
          let totalErrors = 0;
          let totalMistake = 0;
          let totalScore = 0;
          let totalTime = 0;
          for (let i = 0; i < userScores.length; i += 1) {
            const element = userScores[i];
            totalCheck += element.scorm_scores.total_checks_count;
            totalErrors += element.scorm_scores.total_errors_count;
            totalMistake += element.scorm_scores.total_mistake_count;
            totalScore += element.scorm_scores.total_score;
            totalTime += element.scorm_scores.total_time;
          }
          const obj = {};
          obj.user_id = userScores[0].user_id;
          obj.name = userScores[0].name;
          obj.total_checks_count = totalCheck;
          obj.total_errors_count = totalErrors;
          obj.total_mistake_count = totalMistake;
          obj.avg_score = totalScore / userScores.length;
          obj.total_time = totalTime;
          this.tableData.push(obj);
        }
        this.reportAll = [];
        // eslint-disable-next-line
        for (const item of allAssignments) { 
          const userScores = allAssignments.filter(x => x.user_id === item.user_id);
            // eslint-disable-next-line
            for (const scores of userScores) {
              // const obj = {};
              // obj.user_id = scores.users.user_id;
              // obj.name = scores.users.name;
              // obj.scores = scores.users.scorm_scores;
              this.reportAll.push(scores);
            }
        }
        // console.log('reportAll', this.reportAll);
        this.type = 'group';
        this.tableData = _.uniqBy(this.tableData, 'user_id');
        this.studentDetails = assignmentDatas;
        // console.log('testDatas', this.tableData);
        this.ready = true;
      } else {
        for (const item of assignmentDatas) { //eslint-disable-line
          const userScores = assignmentDatas.filter(x => x.users.user_id === item.users.user_id);
          let totalCheck = 0;
          let totalErrors = 0;
          let totalMistake = 0;
          let totalScore = 0;
          let totalTime = 0;
          let scormName = null;
          let assignmentId = 0;
          for (let i = 0; i < userScores.length; i += 1) {
            const element = userScores[i];
            totalCheck = element.users.scorm_scores.total_checks_count;
            totalErrors = element.users.scorm_scores.total_errors_count;
            totalMistake = element.users.scorm_scores.total_mistake_count;
            totalScore = element.users.scorm_scores.total_score;
            totalTime = element.users.scorm_scores.total_time;
            scormName = element.scorm_name;
            assignmentId = element.assignment_id;
            const obj = {};
            obj.user_id = userScores[0].users.user_id;
            obj.name = userScores[0].users.name;
            obj.total_checks_count = totalCheck;
            obj.total_errors_count = totalErrors;
            obj.total_mistake_count = totalMistake;
            obj.avg_score = totalScore;
            obj.total_time = totalTime;
            obj.scorm_name = scormName;
            obj.assignment_id = assignmentId;
            this.tableData.push(obj);
          }
        }
        this.type = 'individual';
        this.tableData = _.uniqBy(this.tableData, 'assignment_id');
        this.studentDetails = assignmentDatas;
        this.ready = true;
      }
      document.getElementById('btnPrint').disabled = false;
    },
    millisToMinutesAndSeconds(millis) {
      let minutes = Math.floor(millis / 60000);
      let seconds = ((millis % 60000) / 1000).toFixed(0);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `${minutes}`;
      }
      return `${minutes}:${seconds}`;
    },
    print() {
      window.print();
    },
    getStudentDetails(studentId) {
      this.modalLoading = false;
      const uniqAssignments = _.uniqBy(this.studentDetails, 'assignment_name', 'assignment_start_date', 'assignment_end_date');
      if (studentId) { // Bu bir group assignment ise
        const detailsObj = {};
        detailsObj.user_name = this.studentDetails[0].users.find(x => x.user_id === studentId).name;
        detailsObj.assignments = [];
        // eslint-disable-next-line
        for (const assignment of uniqAssignments) {
          const assignmentObj = {};
          assignmentObj.assignment_name = assignment.assignment_name;
          assignmentObj.assignment_start_date = assignment.assignment_start_date;
          assignmentObj.assignment_end_date = assignment.assignment_end_date;
          assignmentObj.scorms = [];
          const assignmentScorms = this.studentDetails.filter(x =>
            x.assignment_name === assignment.assignment_name
            && x.assignment_start_date === assignment.assignment_start_date
            && x.assignment_end_date === assignment.assignment_end_date);
          // eslint-disable-next-line
          for (const scorm of assignmentScorms) {
            const scormData = this.assignments.find(x => x.scorm.scorm_id === scorm.scorm_id).scorm;
            const scormObj = {};
            scormObj.scorm_name = scormData.scorm_name;
            scormObj.scorm_big_image = scormData.scorm_big_image;
            scormObj.scorm_image = scormData.scorm_image;
            const scormScores = assignmentScorms.find(x => x.scorm_id === scormData.scorm_id)
              .users.find(x => x.user_id === studentId).scorm_scores;
            scormObj.check = scormScores.total_checks_count;
            scormObj.error = scormScores.total_errors_count;
            scormObj.mistake = scormScores.total_mistake_count;
            scormObj.score = scormScores.total_score;
            scormObj.time = scormScores.total_time;
            assignmentObj.scorms.push(scormObj);
          }
          detailsObj.assignments.push(assignmentObj);
        }
        this.modalData = detailsObj;
        setTimeout(() => {
          this.modalLoading = true;
        }, 500);
      } else {
        const detailsObj = {};
        detailsObj.user_name = this.studentDetails[0].users.name;
        detailsObj.assignments = [];
        // eslint-disable-next-line
        for (const assignment of uniqAssignments) {
          const assignmentObj = {};
          assignmentObj.assignment_name = assignment.assignment_name;
          assignmentObj.assignment_start_date = assignment.assignment_start_date;
          assignmentObj.assignment_end_date = assignment.assignment_end_date;
          assignmentObj.scorms = [];
          const assignmentScorms = this.studentDetails.filter(x =>
            x.assignment_name === assignment.assignment_name
            && x.assignment_start_date === assignment.assignment_start_date
            && x.assignment_end_date === assignment.assignment_end_date);
          // eslint-disable-next-line
          for (const scorm of assignmentScorms) {
            const scormData = this.assignments.find(x => x.scorm.scorm_id === scorm.scorm_id).scorm;
            const scormObj = {};
            scormObj.scorm_name = scormData.scorm_name;
            scormObj.scorm_big_image = scormData.scorm_big_image;
            scormObj.scorm_image = scormData.scorm_image;
            const scormScores = assignmentScorms.find(x => x.scorm_id === scormData.scorm_id)
              .users.scorm_scores;
            scormObj.check = scormScores.total_checks_count;
            scormObj.error = scormScores.total_errors_count;
            scormObj.mistake = scormScores.total_mistake_count;
            scormObj.score = scormScores.total_score;
            scormObj.time = scormScores.total_time;
            assignmentObj.scorms.push(scormObj);
          }
          detailsObj.assignments.push(assignmentObj);
        }
        this.modalData = detailsObj;
        setTimeout(() => {
          this.modalLoading = true;
        }, 1000);
      }
    },
    reportDetails(userId) {
      this.checkReport = [];
      // eslint-disable-next-line
        for ( const student of this.studentDetails ) {
          if (this.selectedType === 'group') {
            const checkData = student.users.filter(x => x.user_id === userId)[0];
            const checkObj = {};
            checkObj.assignment_name = student.assignment_name;
            checkObj.assignment_id = student.assignment_id;
            checkObj.scorm_id = student.scorm_id;
            checkObj.scorm_name = student.scorm_name;
            checkObj.scorm_image = student.scorm_image;
            checkObj.report = checkData;
            this.checkReport.push(checkObj);
          }
          if (this.selectedType === 'individual') {
            const checkData = student.users;
            const checkObj = {};
            checkObj.assignment_name = student.assignment_name;
            checkObj.assignment_id = student.assignment_id;
            checkObj.scorm_id = student.scorm_id;
            checkObj.scorm_name = student.scorm_name;
            checkObj.scorm_image = student.scorm_image;
            checkObj.report = checkData;
            this.checkReport.push(checkObj);
          }
        }
      return true;
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0px;
  }
}
</style>
