<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">

        <div class="col-md-4 offset-md-4">
          <Search></Search>
        </div>
        <div class="col-md-12">
          <h1 class="title menuTitle" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div class="row" :class="$style.columns" v-else-if="scormData.length > 0">
        <div class="col-md-4" :class="$style.column" v-for="(item,i) in this.scormData" :key="i">
          <Card contentType="free" :id='item.scorm_id' :title='item.scorm_name' :imgpath="item.scorm_image" :score='item.scorm_scores.total_score'
            :scormCode='item.scorm_code' :scormTitle='item.scorm_name' :scormDesc='item.scorm_desc' :packageId='item.package_id' :date="item.package_end_date"></Card>
        </div>
      </div>
      <div class="row" :class="$style.columns" v-else-if="linkData.length > 0">
        <div class="col-md-4" :class="$style.column" v-for="(item,i) in this.linkData" :key="i">
          <Card contentType="link" :id='item.link_id' :title='item.link_name' :imgpath="item.thumbnail_url" :linkUrl='item.link_url'></Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import Card from '@/components/Card';
  import Search from '@/components/Search';

  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    name: 'lessons',
    data() {
      return {
        scormData: [],
        linkData: [],
        title: '',
        ready: false,
      };
    },
    components: {
      HeaderCustom,
      Card,
      Search,
      InnerLoading,
    },
    computed: {
      ...mapState(['freeContents']),
      ...mapGetters(['freeScorms']),
    },
    async beforeMount() {
      await this.getPackages();
      this.setPackages();
      this.ready = true;
    },
    watch: {
      freeContents() {
        this.setPackages();
      },
      freeScorms() {
        this.setPackages();
      },
    },
    methods: {
      ...mapActions(['getPackages']),
      search(text) {
        if (!text) {
          this.setPackages();
        } else {
          this.setPackages(text);
        }
      },
      setPackages(text) {
        const packageId = parseInt(this.$route.params.id, 10);
        if (text) {
          this.scormData = this.freeScorms.filter(x => x.package_id === packageId && x.scorm_name.toLowerCase().includes(text.toLowerCase()));  //eslint-disable-line
          this.linkData = this.freeContents.find(x => x.package_id === packageId).package_links
            .filter(y => y.link_name.includes(text.toLowerCase()));
        } else {
          this.scormData = this.freeScorms.filter(x => x.package_id === packageId);
          this.linkData = this.freeContents.find(x => x.package_id === packageId).package_links;
        }
        this.title = this.freeContents.find(x => x.package_id === packageId).package_name;
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
