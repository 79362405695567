<template>
  <div class="card-body">
    <nav class="d-flex justify-content-center">
      <ul class="pagination mt-1 mb-1">
        <li v-if="data.last_page > 0 && data.current_page === 1" class="page-item disabled">
          <a class="page-link" href="javascript:void(0)" @click="getDataByPagination(1)">«</a>
        </li>
        <li v-else-if="data.last_page > 0" class="page-item">
          <a class="page-link" href="javascript:void(0)" @click="getDataByPagination(1)">«</a>
        </li>
        <template v-for="(item, i) in data.last_page">
          <li v-if="data.current_page === item" class="page-item active"  :key="i">
            <a class="page-link current-page-link" href="javascript:void(0)"
              @click="getDataByPagination(item)">{{ item }}</a>
          </li>
          <li v-else-if="Number(item) <= (Number(currentPage + 5))
            && Number(item) >= Number(currentPage - 5)"
            class="page-item"  :key="i">
            <a class="page-link" href="javascript:void(0)"
              @click="getDataByPagination(item)">{{ item }}</a>
          </li>
        </template>
         <li v-if="data.last_page > 0 && data.current_page === data.last_page"
            class="page-item disabled">
          <a class="page-link" href="javascript:void(0)"
            @click="getDataByPagination(data.last_page)">»</a>
        </li>
        <li v-else-if="data.last_page > 0" class="page-item">
          <a class="page-link" href="javascript:void(0)"
            @click="getDataByPagination(data.last_page)">»</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['data'],
  data() {
    return {
      currentPage: this.data.current_page,
    };
  },
  methods: {
    getDataByPagination(page) {
      this.currentPage = page;
      this.$emit('goToPage', page);
    },
  },
};
</script>

<style src="./pagination.less" lang="less" scoped></style>
