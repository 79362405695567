module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#e70c6a',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: '8WOBmM62Wo0bcnJoXDskY7wbLkzCjOGfQxZxnwsI1n18e6yO',
  // apiKey: '1K3I97MUjADoctE0g6x543UD8NBuN5cGEgJNV7KSI2kzKwq7', // Ted
};
