import Vue from 'vue';
import VueRouter from 'vue-router';
import router from './router';
import VueSelect from 'vue-select';
import VJstree from 'vue-jstree';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';

import App from './App.vue';
import store from '@/stores';

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueCustomTooltip, {
  name: 'VueCustomTooltip',
  color: '#fff',
  background: '#000',
  borderRadius: 12,
  fontWeight: 400,
});
Vue.component('v-select', VueSelect)
Vue.component('v-jstree', VJstree);
Vue.component('datetime', Datetime);
/* Bootstrap */
require('bootstrap/dist/css/bootstrap.min.css');
require('bootstrap/dist/js/bootstrap.min.js');

const isLocal = window.location.hostname === 'localhost';

if (isLocal) {
  window['ga-diasble-UA-127427549-9'] = true;
}

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
