<template>
  <div class="card" :class="$style.profile">
    <div class="row" :class="$style.divProfile">
      <div class="col-md-12 col-sm-12 col-xs-12" @mouseout="showImg" @mouseover="hideImg">
        
        <img :src="imgpath" alt="Profile Image" :class="$style.img" ref="profilePicture">
        <!-- <div class="fa fa-plus-circle fa-4x" aria-hidden="true" :class="$style.uploadButton" ref="uploadBtn">
          <input type="file" :class="$style.fileUpload" @change="onFileSelected">
        </div> -->
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 mt-2" :class="$style.contentTitleDiv">
          <h1 :class="$style.contentTitle">{{profileData.first_name}} {{profileData.last_name}}</h1>
      </div>
        <div class="col-md-12 col-sm-12 col-xs-12 mt-2" :class="$style.customTag">
            <span :class="$style.secondTag" class="badge badge-light">{{profileData.schoolName}}</span>
          </div>
          <div class="col-md-12 col-xs-12" :class="$style.customTag">
            <span :class="$style.secondTag" class="badge badge-light">{{profileData.branchName}}</span>
          </div>
          <div class="col-md-12 col-xs-12" :class="$style.customTag">
            <span :class="$style.secondTagGrade" class="badge badge-light">{{profileData.gradeName}}</span>
          </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userService from '@/services/user';
// import defaultAvatar from '../../../static/img/default-avatar.png';

export default {
  name: 'profile',
  data() {
    return {
      selectedFile: null,
    };
  },
  computed: {
    ...mapGetters(['profileData']),
    imgpath() {
      const avatar = this.profileData.avatar
        ? this.profileData.avatar // : 'https://www.shareicon.net/data/128x128/2016/08/05/806962_user_512x512.png';
        : '/static/img/default-avatar.png';
      return avatar;
    },
  },
  methods: {
    hideImg() {
      this.$refs.profilePicture.style.opacity = 0.5;
      // this.$refs.uploadBtn.style.display = 'block';
      // this.$refs.uploadBtn.style.cursor = 'pointer';
    },
    showImg() {
      this.$refs.profilePicture.style.opacity = 1;
      // this.$refs.uploadBtn.style.display = 'none';
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.upload(this.selectedFile);
    },
    async upload(data) {
      const formData = new FormData();
      formData.append('avatar', data);
      const isPictureUpload = await userService.setProfilePicture(formData);
      console.log('deneme', isPictureUpload);
      if (isPictureUpload) {
        this.$store.dispatch('getUserDetails');
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>
