import _ from 'lodash';
import contentService from '../services/content';

export default {
  state: {
    freeContents: [],
    assignments: [],
    assignmentss: [],
    collections: [],
    scorms: [],
    individualAssignments: [],
    assignmentsCount: 0,
  },
  getters: {
    stats: (state) => {
      let sumScore = 0;
      state.assignments.forEach((item) => {
        if (item.assignment_type === 'individual') {
          sumScore += item.scorm.scorm_scores.total_score;
        } else {
          sumScore += item.scorm.scorm_scores.average_total_score;
        }
      }); // ! item'in içinde score olmadığı için bu değer kullanılamıyor
      const stats = {
        count: {
          total: state.assignments.length,
          notStarted: state.assignments.filter(x => x.state_name === 'Not Started').length,
          ongoing: state.assignments.filter(x => x.state_name === 'Ongoing').length,
          completed: state.assignments.filter(x => x.state_name === 'Completed').length,
        },
        avgScore: sumScore / state.assignments.length,
      };
      // console.log('stats1', stats);
      return stats;
    },
    stats2: (state) => {
      let sumScore = 0;
      state.individualAssignments.forEach((item) => {
        if (item.assignment_type === 'individual') {
          sumScore += item.scorm.scorm_scores.total_score;
        }
      }); // ! item'in içinde score olmadığı için bu değer kullanılamıyor
      const stats = {
        count: {
          total: state.individualAssignments.length,
          notStarted: state.individualAssignments.filter(x => x.state_name === 'Not Started').length,
          ongoing: state.individualAssignments.filter(x => x.state_name === 'Ongoing').length,
          completed: state.individualAssignments.filter(x => x.state_name === 'Completed').length,
        },
        avgScore: sumScore / state.individualAssignments.length,
      };
      // console.log('stats2', stats);
      return stats;
    },
    freeScorms: (state) => {
      const arr = [];
      state.freeContents.forEach((free) => {
        free.package_scorms.forEach((scorm) => {
          scorm.package_id = free.package_id;
          scorm.package_end_date = free.package_end_date;
          arr.push(scorm);
        });
      });
      return arr;
    },
    lmsHomeworkCount: (state) => {
      const now = new Date();
      return state.assignments.filter(x => new Date(x.start_date).getTime() < now.getTime() &&
        new Date(x.end_date).getTime() > now.getTime() && x.state_name !== 'Completed').length;
    },
    activeLmsHomeworks: (state) => {
      const now = new Date();
      const activeHomeworks = state.assignments
        .filter(x => new Date(x.start_date).getTime() < now.getTime() &&
        new Date(x.end_date).getTime() > now.getTime() && x.state_name !== 'Completed');
      state.assignmentsCount = activeHomeworks.length;
      if (activeHomeworks.length > 0) {
        return _.orderBy(activeHomeworks.slice(0, 4), ['end_date', 'scorm.scorm_name'], ['desc', 'asc']);
      }
      return _.orderBy(state.assignments.slice(0, 4), ['end_date', 'scorm.scorm_name'], ['desc', 'asc']);
    },
  },
  mutations: {
    setPackages(state, payload) {
      if (state.roleType === 'teacher') {
        state.freeContents = payload;
      } else if (state.roleType === 'student') {
        state.freeContents = payload ? payload.filter(x => x.package_type === 'free') : [];
      }
    },
    setCollections(state, payload) {
      state.collections = payload;
    },
    setScorms(state, payload) {
      state.scorms = payload;
    },
    setLmsAssignments(state, payload) {
      for (const item of payload) { //eslint-disable-line
        // console.log('payload', payload);
        if (item.scorm.scorm_scores.total_score === 100) {
          item.state_name = 'Completed';
        } else if (item.scorm.scorm_scores.total_score === 0) {  //eslint-disable-line
          item.state_name = 'Not Started';
        } else {
          item.state_name = 'Ongoing';
        }
      }
      payload = _.orderBy(payload, ['start_date'], ['desc']);
      state.assignments = payload;
    },
    setLmsIndAssignments(state, payload) {
      for (const item of payload) { //eslint-disable-line
        if (item.scorm.scorm_scores.total_score === 100) {
          item.state_name = 'Completed';
        } else if (item.scorm.scorm_scores.total_score === 0) {  //eslint-disable-line
          item.state_name = 'Not Started';
        } else {
          item.state_name = 'Ongoing';
        }
      }
      payload = _.orderBy(payload, ['start_date'], ['desc']);
      state.individualAssignments = payload;
    },
    setAssignments(state, payload) {
      state.assignmentss = payload.data.data;
    },
    setIndAssignments(state, payload) {
      state.individualAssignments = payload.data.data;
    },
  },
  actions: {
    async getPackages(context) {
      const res = await contentService.getPackages();
      context.commit('setPackages', res.data);
    },
    async getCollections(context) {
      const res = await contentService.getCollections();
      context.commit('setCollections', res.data);
      // if (context.state.roleType === 'teacher') {
      //   data = res.data.filter(x => x.teacher === 1);
      // } else {
      //   data = res.data.filter(x => x.student === 1);
      // }
      // context.commit('setCollections', res);
    },
    async getScorms(context) {
      const res = await contentService.getScorms();
      context.commit('setScorms', res.data);
    },
    async getLmsAssignments(context) {
      const res = await contentService.getAssignments(1, 'id');
      context.commit('setAssignments', res);
      context.commit('setLmsAssignments', res.data.data);
    },
    async getLmsIndAssignments(context) {
      const res = await contentService.getAssignments(1, 'id', 'individual');
      context.commit('setLmsIndAssignments', res.data.data);
    },
  },
};
