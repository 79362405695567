<template>
  <div>
    <div :class='$style.loading'>
      <p :class="$style.loadingText">{{message}}</p>      
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: ['message'],
  data() {
    return {};
  },
};
</script>

<style src='./style.less' lang='less' module/>
