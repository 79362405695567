import axios from 'axios';
import tools from '@/tools';
import configuration from '@/config';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const token = tools.cookie.get(configuration.global_key.tokenName);
  config.baseURL = 'https://www.ueslms.com/api/v2';
  // config.baseURL = 'https://www.ueslms.com/api/v1';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(res => res, err => err);
export default () => instance;
