export default {
  state: {
    googleCourses: [],
    googleCourseWorks: [],
    googleReady: false,
  },
  getters: {
    courseworks: (state) => {
      // const list = state.googleCourseWorks.filter(x => x.workType === 'ASSIGNMENT');
      // return list;
      const currentCourseworks = [];
      const dateObj = new Date();
      const month = dateObj.getUTCMonth() + 1; // months from 1-12
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();
      for (const item of state.googleCourseWorks) { // eslint-disable-line
        if (item.dueDate) {
          if (item.dueDate.year >= year) {
            if (item.dueDate.month >= month) {
              if (item.dueDate.day && item.dueDate.day >= day) {
                currentCourseworks.push(item);
              }
            }
          }
        }
      }
      return currentCourseworks.filter(x => x.workType === 'ASSIGNMENT');
    },
    courseworkCount: (state) => { // eslint-disable-line
      // return state.googleCourseWorks.filter(x => x.workType === 'ASSIGNMENT').length;
      const currentCourseworks = [];
      const dateObj = new Date();
      const month = dateObj.getUTCMonth() + 1; // months from 1-12
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();
      for (const item of state.googleCourseWorks) { // eslint-disable-line
        if (item.dueDate) {
          if (item.dueDate.year >= year) {
            if (item.dueDate.month >= month) {
              if (item.dueDate.day && item.dueDate.day >= day) {
                currentCourseworks.push(item);
              }
            }
          }
        }
      }
      return currentCourseworks.filter(x => x.workType === 'ASSIGNMENT').length;
    },
  },
  mutations: {
    setGoogleCourses(state, payload) {
      state.googleCourses = payload;
    },
    setGoogleCourseWorks(state, payload) {
      state.googleCourseWorks = payload;
      state.googleReady = true;
    },
  },
  actions: {
    async getGoogleClassroomData(context) {
      const googleData = await window.fetchCourses();
      context.commit('setGoogleCourses', googleData.courses);
      context.commit('setGoogleCourseWorks', googleData.courseWorks);
    },
  },
};
