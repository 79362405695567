<template>
  <div :class="$style.divSecondStep">
    <div v-if="!ready">
      <InnerLoading :class="$style.divLoadingHome"></InnerLoading>
    </div>
    <div v-else class="row m-0">
      <div class="col-md-6">
        <div class="alert alert-info" :class="$style.customAlert">
          <small>Please choose a course and lessons. You should select lesson card from right column to the left. If you add many
          resources then will be created many assignments with one resource. Please remember that students have to have access
          to this course.</small>
          </div>
        <div class="row text-center mt-3" v-if="selectedLessons.length > 0">
          <div class="col-md-10 ml-5 mr-5">
            <button class="btn btn-danger" @click="unselectAllScorms">Remove All Lessons</button>
          </div>
        </div>
        <div class="row" v-if="selectedLessons.length > 0" >
          <div class="col-md-10 ml-5 mr-5" v-for="(item,i) in selectedLessons" :key="i" :class='$style.divSelectedLessons'>
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-lg-5 col-md-12 text-center" :class="$style.lessonTitle">
                {{item.name}}
              </div>
              <div class="col-lg-5 col-md-12 text-center">
                <img :src="item.big_image ? item.big_image : item.image" :class="$style.lessonImg">
              </div>
              <div class='col-lg-2 col-md-12'>
                <button class='btn btn-danger btn-block mb-3' @click='unselectLesson(item.id)'>
                  <span class='fa fa-minus-circle'></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row" :class="$style.customRow">
          <div class="col-md-3">
            <label :class="$style.customLabel">Collections:*</label>
          </div>
          <div class="col-md-9">
            <v-select v-model="selectedCollection" label='name' :options="parentCollections"></v-select>
          </div>
        </div>
        <div class="row" :class="$style.customRow">
          <div class="col-md-3">
            <label :class="$style.customLabel">Courses:*</label>
          </div>
          <div class="col-md-9">
            
            <v-select v-if="this.selectedCollection !== 'Select a Collection'" v-model="selectedCourse" label='name' :options="this.coursesArr"></v-select>
            <v-select v-else v-model="selectedCourse" label='name'></v-select>

            
          </div>
        </div>
        <div class="row text-center mt-3" v-if="this.lessonsArr.length > 0 && this.lessonsArr.filter(x => x.isSelected === false).length > 0">
          <div class="col-md-10 ml-5 mr-5">
            <button class="btn btn-primary" @click="addedAllScorms">Select All Lessons</button>
          </div>
        </div>
        <div class="row" v-if="this.lessonsArr.length > 0" :class="$style.customRow">
          <div class="col-md-10 ml-5 mr-5" v-for="(item,i) in this.lessonsArr.filter(x => x.isSelected === false)" :key="i" :class="$style.lessonDiv">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-lg-5 col-md-12 text-center" :class="$style.lessonTitle">
                {{item.name}}
                 <p v-if="item.isHistory" style="font-weight:bold; text-decoration:underline; color:green; font-size:10px;">Already assigned</p>
              </div>
             
              <div class="col-lg-5 col-md-12 text-center">
                <img :src="item.big_image ? item.big_image : item.image" :class="$style.lessonImg">
              </div>
              <div class='col-lg-2 col-md-12'>
                <button class='btn btn-success btn-block mb-3' @click='selectLesson(item.id)'>
                  <span class='fa fa-plus'></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import Card from '@/components/Card';
import InnerLoading from '@/components/InnerLoading';

import service from '@/services/content';

/*eslint-disable*/
export default {
  name: 'secondStep',
  data() {
    return {
      selectedCollection: 'Select a Collection',
      selectedCollectionId: 0,
      selectedCourse: 'Select a Collection',
      allCourses: [],
      historyScorms: [],
      globalLessonsArr: [],
      selectedLessons: [],
      parentCollections: [],
      childCollections: [],
      ready: true,
    };
  },
  props: ['resources'],
  async beforeMount() {
    if (this.resources.length > 0) {
      this.selectedLessons = this.resources;
    }
    this.parentCollections = this.collections.data.filter(x => x.parent === 0);
    let getHistory = await service.getHistoryScorms();
    this.historyScorms = getHistory.scorm_ids;
  },
  watch: {
    selectedCollection(newVal) {
      this.ready = false;
      if(newVal !== null) {
        this.selectedCollectionId = newVal.id;
        this.selectedCourse = 'Select a Course';
      } else {
        this.selectedCollection = 'Select a Collection';
        this.selectedCollectionId = 0;
        this.selectedCourse = null;
      }
      this.ready = true;
    },
    async selectedCourse(newVal) {
      this.ready = false;
      if(newVal !== null && newVal !== 'Select a Course') {
        this.setGlobalLessons(newVal.id);
      } else {
        if(this.selectedCollectionId !== 0) {
          this.selectedCourse = 'Select a Course';          
        } else {
          this.selectedCourse = 'Select a Collection';
        }
      }
      this.ready = true;
    },
  },
  computed: {
    ...mapState(['collections', 'scorms']),
    coursesArr() {
      this.ready = false;
      if (this.selectedCollectionId) {
        this.ready = true;
        return this.collections.data.filter(x => x.parent === this.selectedCollectionId);
      }
      this.ready = true;
    },
    lessonsArr() {
      return this.globalLessonsArr;
    },
  },
  methods: {
    async setGlobalLessons(id) {
      this.ready = false;
      const res = await service.getScorms(id, 1, 200);
      this.globalLessonsArr = res.data;

      for (const item of this.globalLessonsArr) {
        if(this.inArray(item.id,this.historyScorms)){
          item.isHistory = true;
        }else{
          item.isHistory = false;
        }
      }

      //this.selectedLessons'da olan itemlerı buradan sil
      this.globalLessonsArr.forEach(item => {
        item.isSelected = false;
      });
      const deleteArray = [];
      for (const item of this.globalLessonsArr) {
        item.isSelected = false;
        // item.icon_url = `https://www.yourlearningplace.com/${item.icon_url}`;
        const element = this.selectedLessons.find(x => x.id == item.id);
        if (element) {
          deleteArray.push(item);
        }
      }
      for (const item of deleteArray) {
        const index = _.findIndex(this.globalLessonsArr, x => {
          return x.id == item.id;
        });
        this.globalLessonsArr.splice(index, 1);
      }
      this.ready = true;
    },
    inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] == needle) return true;
    }
    return false;
    },
    unselectLesson(lessonId) {
      const lesson = this.globalLessonsArr.find(x => x.id == lessonId);
      if (lesson) {
        lesson.isSelected = false;
      }
      const index = _.findIndex(this.selectedLessons, item => {
        return item.id == lessonId;
      });
      this.selectedLessons.splice(index, 1);
      this.$emit('selectedLessons', this.selectedLessons);
    },
    selectLesson(lessonId) {
      const lesson = this.globalLessonsArr.find(x => x.id == lessonId);
      lesson.isSelected = true;
      this.selectedLessons.push(lesson);
      this.$emit('selectedLessons', this.selectedLessons);
    },
    async addedAllScorms() {
      this.ready = false;
      await this.lessonsArr.forEach(element => {
        const lesson = this.globalLessonsArr.find(x => x.id == element.id);
        lesson.isSelected = true;
        this.selectedLessons.push(lesson);
      });
      this.ready = true;
    },
    async unselectAllScorms() {
      this.ready = false;
      await this.lessonsArr.forEach(element => {
        const lesson = this.globalLessonsArr.find(x => x.id == element.id);
        if (lesson) {
          lesson.isSelected = false; 
        }
      });
      this.selectedLessons = [];
      this.ready = true;
    },
  },
  components: {
    Card,
    InnerLoading,
  },
  beforeDestroy() {
    const data = {};
    data.assignment_scorms = this.selectedLessons;
    this.$parent.setAssignmentData(data);
  },
};
</script>

<style src='./style.less' lang='less' module/>
